import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='fa-chart-pie'
      />

      <SidebarMenuItemWithSub to='/products' title={intl.formatMessage({id: 'PRODUCT'})}>
        <SidebarMenuItem
          to='/products/list?page=1'
          title={intl.formatMessage({id: 'PRODUCT_GROUPS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/products/product-colors/list?page=1'
          title={intl.formatMessage({id: 'PRODUCTS'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/products/wholesale/list?page=1'
          title={intl.formatMessage({id: 'WHOLESALE'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/promotions' title={intl.formatMessage({id: 'PROMOTIONS'})}>
        <SidebarMenuItem
          to='/promotions/list?page=1'
          title={intl.formatMessage({id: 'PRODUCT_PROMOTION'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/pages' title={intl.formatMessage({id: 'PAGES'})}>
        <SidebarMenuItem
          to='/pages/list?page=1'
          title={intl.formatMessage({id: 'PAGES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          title={intl.formatMessage({id: 'ADD_NEW_PAGE'})}
          to='/pages/create'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/users' title={intl.formatMessage({id: 'USERS'})}>
        <SidebarMenuItem
          to='/users/list?page=1'
          title={intl.formatMessage({id: 'USERS_LIST'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/settings' title={intl.formatMessage({id: 'SETTINGS'})}>
        <SidebarMenuItem
          title={intl.formatMessage({id: 'MENUS'})}
          to='/settings/menus/list?page=1'
          hasBullet={true}
        />
        <SidebarMenuItem
          title={intl.formatMessage({id: 'CURRENCIES'})}
          to='/settings/currencies/list?page=1'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/orders' title={intl.formatMessage({id: 'ORDERS'})}>
        <SidebarMenuItem
          to='/orders/order-list/list?page=1'
          title={intl.formatMessage({id: 'ORDER_LIST'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/reports' title={intl.formatMessage({id: 'REPORTS'})}>
        <SidebarMenuItem
          to='/reports/daily-sell/list?page=1'
          title={intl.formatMessage({id: 'DAILY_SELL'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/carts' title={intl.formatMessage({id: 'CART_LIST'})}>
        <SidebarMenuItem
          to='/carts/cart-list/list'
          title={intl.formatMessage({id: 'CART_LIST'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/definitions' title={intl.formatMessage({id: 'DEFINITIONS'})}>
        <SidebarMenuItem
          to='/definitions/categories/list?page=1'
          title={intl.formatMessage({id: 'CATEGORIES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/definitions/colors/list'
          title={intl.formatMessage({id: 'COLOR'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/definitions/variation-list/list?page=1'
          title={intl.formatMessage({id: 'VARIATION_LIST'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/definitions/badges/list?page=1'
          title={intl.formatMessage({id: 'BADGES'})}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/definitions/tags/list?page=1'
          title={intl.formatMessage({id: 'TAGS'})}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
