export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const pretifyName = (name: string) => {
  return name
    .replace(/[-_.]/g, ' ')
    .replace(/ +/g, ' ')
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const intlDateFormatter = (intl: any, utcDate: string, timezone?: string) => {
  const formattedUTCDate = `${new Date(utcDate)}Z`

  // Manually format the date with hyphens
  const createdYear = intl.formatDate(formattedUTCDate, {
    year: 'numeric',
    timezone: timezone ?? 'Europe/Istanbul',
  })
  const createdMonth = intl.formatDate(formattedUTCDate, {
    month: '2-digit',
    timezone: timezone ?? 'Europe/Istanbul',
  })
  const createdDay = intl.formatDate(formattedUTCDate, {
    day: '2-digit',
    timezone: timezone ?? 'Europe/Istanbul',
  })

  const formattedDate = `${createdYear}-${createdMonth}-${createdDay}`

  const formattedTime = intl.formatTime(formattedUTCDate, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timezone: timezone ?? 'Europe/Istanbul',
  })

  return {
    formattedDate,
    formattedTime,
  }
}
